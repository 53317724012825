<script setup lang="ts">
import { dir } from '@@/bits/current_dir'
import { __ } from '@@/bits/intl'
import ImageThumbnail from '@@/library/v4/components/ImageThumbnail.vue'
import OzContainedButton, {
  OzContainedButtonColorScheme,
  OzContainedButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { storeToRefs } from 'pinia'

const { isDarkMode } = storeToRefs(useDarkModeStore())
const { isSmallerThanTabletLandscape } = storeToRefs(useWindowSizeStore())

const footerIllustrationDarkUrl = 'https://padlet.net/how-it-works/v1/illustration_call_to_action_dark.webp'
const footerIllustrationLightUrl = 'https://padlet.net/how-it-works/v1/illustration_call_to_action_light.webp'

const callToActionOriginalIllustrationWidth = 10386
const callToActionOriginalIllustrationHeight = 992
const callToActionGroupHeight = 248
</script>

<template>
  <div
    :class="[
      'flex flex-col desktop:flex-row',
      'items-center',
      'mb-8 desktop:mb-24',
      'pt-4 desktop:pt-0',
      'px-4 desktop:px-0',
      'pb-4 desktop:pb-12',
    ]"
  >
    <!-- We only show the illustration in desktop viewport  -->
    <div class="hidden desktop:block h-62">
      <ImageThumbnail
        :title="''"
        :src="isDarkMode ? footerIllustrationDarkUrl : footerIllustrationLightUrl"
        :original-image-height="callToActionOriginalIllustrationWidth"
        :original-image-width="callToActionOriginalIllustrationHeight"
        :width="
          (callToActionGroupHeight * callToActionOriginalIllustrationWidth) / callToActionOriginalIllustrationHeight
        "
        :height="callToActionGroupHeight"
        :resize-strategy="'fit'"
        :process-image="true"
        :object-position="dir() === 'ltr' ? 'right' : 'left'"
        aria-hidden="true"
        :lazy-loading="false"
        data-testid="callToActionStartIllustration"
      />
    </div>
    <div :class="['flex flex-col', 'shrink-0', 'items-center', 'desktop:pb-1.25', 'desktop:w-[890px]']">
      <div
        :class="[
          'flex flex-col',
          'items-center justify-center',
          'space-y-2',
          'text-center',
          'px-4 desktop:px-0',
          'py-6 desktop:py-12',
        ]"
      >
        <span class="text-heading-2 desktop:text-heading-1 font-semibold">
          {{ __('How do I get started?') }}
        </span>
        <span class="text-body desktop:text-heading-2">
          {{ __('It’s free to sign up and takes just 10 seconds.') }}
        </span>
      </div>
      <OzContainedButton
        href="/auth/signup"
        :color-scheme="OzContainedButtonColorScheme.Primary"
        :size-preset="
          isSmallerThanTabletLandscape ? OzContainedButtonSizePreset.H40px : OzContainedButtonSizePreset.H64px
        "
        data-testid="callToActionButton"
      >
        {{ __('Sign up for free') }}
      </OzContainedButton>
    </div>
    <!-- We only show the illustration in desktop viewport  -->
    <div class="hidden desktop:block h-62">
      <ImageThumbnail
        :title="''"
        :src="isDarkMode ? footerIllustrationDarkUrl : footerIllustrationLightUrl"
        :original-image-height="callToActionOriginalIllustrationWidth"
        :original-image-width="callToActionOriginalIllustrationHeight"
        :width="
          (callToActionGroupHeight * callToActionOriginalIllustrationWidth) / callToActionOriginalIllustrationHeight
        "
        :height="callToActionGroupHeight"
        :resize-strategy="'fit'"
        :process-image="true"
        :object-position="dir() === 'ltr' ? 'left' : 'right'"
        aria-hidden="true"
        :lazy-loading="false"
        data-testid="callToActionEndIllustration"
      />
    </div>
  </div>
</template>
